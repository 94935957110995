<template>
  <v-container>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>发行说明</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
          @keydown.enter="searchItem"
          label="关键字搜索（按回车）"
          prepend-inner-icon="search"
          solo
          flat
          dense
          hide-details
          clearable
        ></v-text-field>
      </v-toolbar>
      <v-card-subtitle>
        结算系统版本更新说明。由开发商进行填写和编辑。
      </v-card-subtitle>
      <v-data-table
        :headers="headerTable"
        :items="releaseList"
        :options.sync="optionsTable"
        :server-items-length="releaseCount"
        :loading="releaseLoading"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
        item-key="_id"
        transition
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>更新记录</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" depressed @click="editItem()" color="primary">发布版本</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.version="{ item }">
<!--          <td nowrap="true"></td>-->
          <td nowrap="true"><core-version :id="item._id">v{{item.version}}</core-version></td>
        </template>
        <template v-slot:item.publish_date="{ item }">
          <td nowrap="true">{{item.publish_date | formatDate}}</td>
        </template>
        <template v-slot:item.publisher="{ item }">
          <td nowrap="true" v-if="item.publisher"><account-dialogs :account="item.publisher._id" class="v-list-item--link">{{item.publisher.personal ? item.publisher.personal.name : '*未设置姓名'}}</account-dialogs></td>
        </template>
        <template v-slot:item.creator="{ item }">
          <td nowrap="true" v-if="item.creator"><account-dialogs :account="item.creator._id" class="v-list-item--link">{{item.creator.personal ? item.creator.personal.name : '*未设置姓名'}}</account-dialogs></td>
        </template>
        <template v-slot:item.createTime="{ item }">
          <td nowrap="true">{{item.createTime | formatTime}}</td>
        </template>
        <template v-slot:item.updateTime="{ item }">
          <td nowrap="true">{{item.updateTime | formatTime}}</td>
        </template>
        <template v-slot:item.actions="{ item }">
          <td nowrap="true">
            <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
            <v-icon small @click="deleteItem(item)">delete</v-icon>
          </td>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" depressed @click="getDataList()">刷新</v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dlgEdit"  persistent max-width="650px" :retain-focus="false" eager>
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{editTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.version"
                  :rules="[rules.required]"
                  type="text"
                  prefix="v"
                  label="版本号"
                  hint="请输入版本标示"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="dataTimeMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.publish_date"
                      label="发布日期"
                      hint="请选择版本的发布日期"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="editedItem.publish_date" @input="dataTimeMenu = false" no-title scrollable></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <editor v-if="activeEdit" v-model="editedItem.context" :init="editorInit" :id="'release_'+ editedItem._id"></editor>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dlgConfirm" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">确定要删除指定项目？</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDelete">取消</v-btn>
          <v-btn color="secondary" text @click="deleteItemConfirm">确认删除</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
// eslint-disable-next-line
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/link'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/preview'
import store from "@/store";
import {
  FETCH_RELEASE_LIST,
  PUBLISH_RELEASE,
  EDIT_RELEASE,
  DELETE_RELEASE,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  components: {
    Editor
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
        negative: val => ( val >= 0) || '不能小于0'
      },
      valid: true,
      dlgEdit: false,
      dlgConfirm: false,
      query: {
        key: '',
      },
      defaultQuery: {
        key: '',
      },
      headerTable: [
        {text: '版本', width: '125px', value: 'version'},
        {text: '发布日期', width: '125px', value: 'publish_date'},
        {text: '发布人', width: '125px', value: 'publisher'},
        {text: '编辑人', width: '125px', value: 'creator'},
        {text: '创建时间', width: '125px', value: 'createTime'},
        {text: '更新时间', width: '125px', value: 'updateTime'},
        {text: '操作', width: '85px', value: 'actions', sortable: false}
      ],
      optionsTable: {},
      dataTimeMenu: false,
      activeEdit: false,
      editedItem: {
        _id: '',
        version: '',
        context: '',
        publish_date: '',
      },
      defaultItem: {
        _id: '',
        version: '',
        context: '',
        publish_date: '',
      },
      editorInit: {
        language_url: '/tinymce/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/ui/MDass',
        content_css: '/tinymce/skins/content/MDass/content.css',
        height: 350,
        branding: false,
        menubar: false,
        statusbar: false,
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body,h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif; }",
        font_formats: "Roboto=roboto;",
        plugins: 'lists wordcount link hr preview',
        toolbar: 'bold italic underline strikethrough | fontselect fontsizeselect formatselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | ' +
          'bullist numlist outdent indent | link unlink | removeformat undo redo',
      },
    }
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "releaseList",
      "releaseLoading",
      "releaseCount",
    ]),
    editTitle () {
      return this.editedItem._id ? '编辑版本说明' : '发布新版本'
    },
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
  },
  methods: {
    goBack() {
      this.$router.push({path: '/distributor/system'});
    },
    searchItem() {
      this.optionsTable.page = 1
      this.getDataList({ key: this.query.key })
    },
    getDataList() {
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key=' + this.query.key

      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_RELEASE_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    editItem(item = {}) {
      this.editedItem = Object.assign({}, item)
      this.editedItem.publish_date = this.$options.filters['formatDate'](this.editedItem.publish_date, this.that)
      this.dlgEdit = true
      this.activeEdit = true
    },
    submit() {
      let strInsert = {}
      strInsert.version = this.editedItem.version
      strInsert.context = this.editedItem.context
      strInsert.publish_date = this.editedItem.publish_date
      if (this.editedItem._id) {
        strInsert._id = this.editedItem._id
        store.dispatch(EDIT_RELEASE, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.query.key = ''
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_RELEASE, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.query.key = ''
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dlgConfirm = true
    },
    closeDelete () {
      this.dlgConfirm = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    deleteItemConfirm () {
      store.dispatch(DELETE_RELEASE, this.editedItem._id)
        .then(() => {
          store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'});
          this.query.key = ''
          this.getDataList()
          this.closeDelete()
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    close () {
      this.activeEdit = false
      this.dlgEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
  }
}
</script>
